@font-face {
	font-family: 'fs-iconfont';
	src: url("/alt/fshr/design3/fonts/fs-iconfont.woff2?bdf03617f7ce684ef064a1e0bc9df737") format("woff2"),
url("/alt/fshr/design3/fonts/fs-iconfont.woff?bdf03617f7ce684ef064a1e0bc9df737") format("woff"),
url("/alt/fshr/design3/fonts/fs-iconfont.ttf?bdf03617f7ce684ef064a1e0bc9df737") format("truetype");
}

@if not variable-exists('webfont-icons') {
	$webfont-icons: () !global;
}

$webfont-icons: (
	'icon-accessibility': 'f101',
	'icon-activities': 'f102',
	'icon-alarm': 'f103',
	'icon-arrow_left': 'f104',
	'icon-arrow_right': 'f105',
	'icon-arrow_right_large': 'f106',
	'icon-banquet': 'f107',
	'icon-bathroom': 'f108',
	'icon-beds': 'f109',
	'icon-blizzard': 'f10a',
	'icon-blowing_snow': 'f10b',
	'icon-boardroom': 'f10c',
	'icon-brightness': 'f10d',
	'icon-business_centre': 'f10e',
	'icon-call': 'f10f',
	'icon-call_alt': 'f110',
	'icon-caret': 'f111',
	'icon-caret_down': 'f112',
	'icon-chat': 'f113',
	'icon-chat_outline': 'f114',
	'icon-checkmark': 'f115',
	'icon-checkmark_simple': 'f116',
	'icon-chevron_left': 'f117',
	'icon-chevron_right': 'f118',
	'icon-classroom': 'f119',
	'icon-close': 'f11a',
	'icon-close_alt': 'f11b',
	'icon-close_circle': 'f11c',
	'icon-cloudy': 'f11d',
	'icon-cloudy_fog': 'f11e',
	'icon-cloudy_sunny_breaks': 'f11f',
	'icon-collapse': 'f120',
	'icon-condo': 'f121',
	'icon-contact_email': 'f122',
	'icon-contact_phone': 'f123',
	'icon-dairy_free': 'f124',
	'icon-decor': 'f125',
	'icon-desktop': 'f126',
	'icon-detached_house': 'f127',
	'icon-dining': 'f128',
	'icon-douban': 'f129',
	'icon-drizzle': 'f12a',
	'icon-egg_free': 'f12b',
	'icon-email': 'f12c',
	'icon-exclusive_amenities': 'f12d',
	'icon-exclusive_offer': 'f12e',
	'icon-express_pressing': 'f12f',
	'icon-eye_closed': 'f130',
	'icon-eye_open': 'f131',
	'icon-eye_outline': 'f132',
	'icon-facebook': 'f133',
	'icon-facebook_tag': 'f134',
	'icon-fitness_facilities': 'f135',
	'icon-floorplan': 'f136',
	'icon-fog-patches': 'f137',
	'icon-fog': 'f138',
	'icon-foursquare': 'f139',
	'icon-freezing_drizzle': 'f13a',
	'icon-freezing_fog': 'f13b',
	'icon-freezing_rain': 'f13c',
	'icon-gallery': 'f13d',
	'icon-gallery_external': 'f13e',
	'icon-gluten_free': 'f13f',
	'icon-googleplus': 'f140',
	'icon-hamburger': 'f141',
	'icon-hamburguer_small': 'f142',
	'icon-haze_variable_clouds': 'f143',
	'icon-heart': 'f144',
	'icon-hollow_square': 'f145',
	'icon-hotel': 'f146',
	'icon-ice_pellets': 'f147',
	'icon-information': 'f148',
	'icon-instagram': 'f149',
	'icon-isolated_showers': 'f14a',
	'icon-item_add': 'f14b',
	'icon-item_remove': 'f14c',
	'icon-itinerary': 'f14d',
	'icon-kids': 'f14e',
	'icon-kitchen': 'f14f',
	'icon-laundry': 'f150',
	'icon-less_sign': 'f151',
	'icon-light_snow': 'f152',
	'icon-location_pin': 'f153',
	'icon-location_pin_brand': 'f154',
	'icon-location_pin_small': 'f155',
	'icon-mail': 'f156',
	'icon-mainly_clear': 'f157',
	'icon-mobile': 'f158',
	'icon-night_clear_cloudy': 'f159',
	'icon-night_cloudy': 'f15a',
	'icon-night_cloudy_clear': 'f15b',
	'icon-night_isolated_showers': 'f15c',
	'icon-night_scattered_flurries': 'f15d',
	'icon-night_scattered_showers': 'f15e',
	'icon-night_snow_rain': 'f15f',
	'icon-night_thunderstorm_chance': 'f160',
	'icon-notes': 'f161',
	'icon-nut_free': 'f162',
	'icon-occupancy': 'f163',
	'icon-offers': 'f164',
	'icon-outbound': 'f165',
	'icon-pagination_line': 'f166',
	'icon-partly_cloudy': 'f167',
	'icon-pause_button_large': 'f168',
	'icon-pause_button_small': 'f169',
	'icon-pinterest': 'f16a',
	'icon-play_button_large': 'f16b',
	'icon-play_button_small': 'f16c',
	'icon-plus_sign': 'f16d',
	'icon-pool': 'f16e',
	'icon-pool_alt': 'f16f',
	'icon-qq': 'f170',
	'icon-quotation_marks_l': 'f171',
	'icon-quotation_marks_outline': 'f172',
	'icon-quotation_marks_s': 'f173',
	'icon-rain': 'f174',
	'icon-reception': 'f175',
	'icon-refreshments': 'f176',
	'icon-room_cleaning': 'f177',
	'icon-scattered_flurries': 'f178',
	'icon-scattered_showers': 'f179',
	'icon-search': 'f17a',
	'icon-shoe': 'f17b',
	'icon-shopping_cart': 'f17c',
	'icon-showers': 'f17d',
	'icon-snow': 'f17e',
	'icon-snow_rain': 'f17f',
	'icon-snow_rain_showers': 'f180',
	'icon-snow_squalls': 'f181',
	'icon-spa': 'f182',
	'icon-sunny': 'f183',
	'icon-sunny_cloudy': 'f184',
	'icon-support_staff': 'f185',
	'icon-tablet': 'f186',
	'icon-theatre': 'f187',
	'icon-thunderstorm': 'f188',
	'icon-thunderstorm_chance': 'f189',
	'icon-tours': 'f18a',
	'icon-townhouse': 'f18b',
	'icon-transportation': 'f18c',
	'icon-tray_removal': 'f18d',
	'icon-tree': 'f18e',
	'icon-tree_large': 'f18f',
	'icon-tripadvisor': 'f190',
	'icon-turn_down_service': 'f191',
	'icon-twitter': 'f192',
	'icon-u_shape': 'f193',
	'icon-valet_parking': 'f194',
	'icon-variable_clouds': 'f195',
	'icon-vegan': 'f196',
	'icon-vegetarian': 'f197',
	'icon-views': 'f198',
	'icon-washer': 'f199',
	'icon-wechat': 'f19a',
	'icon-weibo': 'f19b',
	'icon-wet_snow': 'f19c',
	'icon-wine': 'f19d',
	'icon-youku': 'f19e',
	'icon-youtube': 'f19f',
	'icon-africa-middle-east': 'f1a0',
	'icon-asia-pacific': 'f1a1',
	'icon-beach': 'f1a2',
	'icon-central-south-america': 'f1a3',
	'icon-europe': 'f1a4',
	'icon-exclusives-buyouts': 'f1a5',
	'icon-golf': 'f1a6',
	'icon-north-america': 'f1a7',
	'icon-outdoor-function-space': 'f1a8',
	'icon-resort': 'f1a9',
	'icon-resort-urban': 'f1aa',
	'icon-urban': 'f1ab',
	'icon-call_alt_inverted': 'f1ac',
	'icon-airline_check_in': 'f1ad',
	'icon-flight_arrival': 'f1ae',
	'icon-itinerary_default': 'f1af',
	'icon-mail_default_circle': 'f1b0',
	'icon-ramdan_menu': 'f1b1',
	'icon-shuttle_bus_schedule': 'f1b2',
	'icon-terms_and_conditions': 'f1b3',
	'icon-book': 'f1b4',
	'icon-flight_departure': 'f1b5',
	'icon-itinerary_new': 'f1b6',
	'icon-mobile_key': 'f1b7',
	'icon-residence': 'f1b8',
	'icon-standard_wifi': 'f1b9',
	'icon-time': 'f1ba',
	'icon-chat_default_circle': 'f1bb',
	'icon-flight_info': 'f1bc',
	'icon-multilingual_concierge': 'f1bd',
	'icon-resort_activities': 'f1be',
	'icon-takeaway': 'f1bf',
	'icon-tv_channel_listings': 'f1c0',
	'icon-festive_menu': 'f1c1',
	'icon-in-room_dining': 'f1c2',
	'icon-luxury_bath_products': 'f1c3',
	'icon-pressreader': 'f1c4',
	'icon-safety_and_security': 'f1c5',
	'icon-telephone_directory': 'f1c6',
	'icon-weddings': 'f1c7',
	'icon-commercial_flight': 'f1c8',
	'icon-private_flight': 'f1c9',
	'icon-smoke_free': 'f1ca',
	'icon-photo_and_video': 'f1cb',
	'icon-more': 'f1cc',
	'icon-credit_card': 'f1cd',
	'icon-bellcart_luggage': 'f1ce',
	'icon-shopping': 'f1cf',
	'icon-pet': 'f1d0',
	'icon-covid_testing': 'f1d1',
	'icon-diving_snorkeling': 'f1d2',
	'icon-enhanced_cleaning': 'f1d3',
	'icon-faqs': 'f1d4',
	'icon-find_hotel_status': 'f1d5',
	'icon-health_and_safety': 'f1d6',
	'icon-mask': 'f1d7',
	'icon-surfing': 'f1d8',
	'icon-temperature_check': 'f1d9',
	'icon-unique_stays': 'f1da',
	'icon-wellness': 'f1db',
	'icon-adventure': 'f1dc',
	'icon-arts_culture': 'f1dd',
	'icon-award_winning_spa': 'f1de',
	'icon-cocktail': 'f1df',
	'icon-cold': 'f1e0',
	'icon-comfort': 'f1e1',
	'icon-culinary_experience': 'f1e2',
	'icon-dress_code': 'f1e3',
	'icon-experience': 'f1e4',
	'icon-extension': 'f1e5',
	'icon-family': 'f1e6',
	'icon-hot': 'f1e7',
	'icon-late_checkout': 'f1e8',
	'icon-mountain': 'f1e9',
	'icon-my_neighborhood': 'f1ea',
	'icon-order_grocery': 'f1eb',
	'icon-outdoor_experience': 'f1ec',
	'icon-premium_wifi': 'f1ed',
	'icon-private_jet': 'f1ee',
	'icon-private_retreats': 'f1ef',
	'icon-property_info': 'f1f0',
	'icon-residence_a_la_carte': 'f1f1',
	'icon-residence_dining': 'f1f2',
	'icon-residence_my_building': 'f1f3',
	'icon-residence_services': 'f1f4',
	'icon-room_upgrade': 'f1f5',
	'icon-services_and_amenities': 'f1f6',
	'icon-ski': 'f1f7',
	'icon-sporting_events': 'f1f8',
	'icon-sustainable_travel': 'f1f9',
	'icon-tennis': 'f1fa',
	'icon-windy': 'f1fb',
	'icon-parking': 'f1fc',
	'icon-meetings_and_events': 'f1fd',
	'icon-check_in': 'f1fe',
	'icon-check_out': 'f1ff',
	'icon-itinerary_notification': 'f200',
	'icon-settings': 'f201',
	'icon-call_circle_outline': 'f202',
	'icon-chat_notification': 'f203',
	'icon-chat_notification_large': 'f204',
	'icon-details': 'f205',
	'icon-dining_reservation': 'f206',
	'icon-email_fill': 'f207',
	'icon-exclusive_amenities_filled': 'f208',
	'icon-location_pin_large_fill': 'f209',
	'icon-location_pin_small_fill': 'f20a',
	'icon-no_available_date': 'f20b',
	'icon-alert': 'f20c',
	'icon-edit': 'f20d',
	'icon-environmental_programs': 'f20e',
	'icon-environment': 'f20f',
	'icon-energy_reduction': 'f210',
	'icon-energy_carbon_and_water_reduction': 'f211',
	'icon-documents_and_downloads': 'f212',
	'icon-diversity_inclusion_and_belonging': 'f213',
	'icon-biodiversity_and_conservation': 'f214',
	'icon-governance': 'f215',
	'icon-handshake': 'f216',
	'icon-purchasing_practices': 'f217',
	'icon-social': 'f218',
	'icon-sustainable_food_and_beverage': 'f219',
	'icon-waste_reduction': 'f21a',
	'icon-water_reduction': 'f21b',
	'icon-whats_included': 'f21c',
	'icon-caret_up': 'f21d',
	'icon-lock': 'f21e',
	'icon-safety_and_security_inverted': 'f21f',
	'icon-pr_and_hotel': 'f220',
	'icon-shopping_cart_outlined': 'f221',
	'icon-maintenance': 'f222',
	'icon-storage': 'f223',
	'icon-yacht': 'f224',
	'icon-horseback_riding': 'f225',
	'icon-yoga': 'f226',
	'icon-download': 'f227',
	'icon-person': 'f228',
	'icon-filter': 'f229',
	'icon-switch_view': 'f22a',
	'icon-list': 'f22b',
	'icon-castle': 'f22c',
	'icon-pillow': 'f22d',
	'icon-checkmark_fill': 'f22e',
	'icon-checkmark_outline': 'f22f',
);


@mixin icon($name) {
	$icon: map-get($webfont-icons, $name);
	font-family: 'fs-iconfont' !important;
	content: #{'"\\' + $icon + '"'};
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	text-decoration: none;
	text-transform: none;
	@content;
}

// Used to set the content with the given icon
@mixin iconName($name) {
	$icon: map-get($webfont-icons, $name);
	content: #{'"\\' + $icon + '"'};
	@content;
}

.icon {
	&::before {
		font-family: 'fs-iconfont';
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		font-style: normal;
		font-variant: normal;
		font-weight: normal;
		text-decoration: none;
		text-transform: none;
	}
}

.icon-accessibility {
	&::before {
		@include iconName('icon-accessibility');
	}
}

.icon-activities {
	&::before {
		@include iconName('icon-activities');
	}
}

.icon-alarm {
	&::before {
		@include iconName('icon-alarm');
	}
}

.icon-arrow_left {
	&::before {
		@include iconName('icon-arrow_left');
	}
}

.icon-arrow_right {
	&::before {
		@include iconName('icon-arrow_right');
	}
}

.icon-arrow_right_large {
	&::before {
		@include iconName('icon-arrow_right_large');
	}
}

.icon-banquet {
	&::before {
		@include iconName('icon-banquet');
	}
}

.icon-bathroom {
	&::before {
		@include iconName('icon-bathroom');
	}
}

.icon-beds {
	&::before {
		@include iconName('icon-beds');
	}
}

.icon-blizzard {
	&::before {
		@include iconName('icon-blizzard');
	}
}

.icon-blowing_snow {
	&::before {
		@include iconName('icon-blowing_snow');
	}
}

.icon-boardroom {
	&::before {
		@include iconName('icon-boardroom');
	}
}

.icon-brightness {
	&::before {
		@include iconName('icon-brightness');
	}
}

.icon-business_centre {
	&::before {
		@include iconName('icon-business_centre');
	}
}

.icon-call {
	&::before {
		@include iconName('icon-call');
	}
}

.icon-call_alt {
	&::before {
		@include iconName('icon-call_alt');
	}
}

.icon-caret {
	&::before {
		@include iconName('icon-caret');
	}
}

.icon-caret_down {
	&::before {
		@include iconName('icon-caret_down');
	}
}

.icon-chat {
	&::before {
		@include iconName('icon-chat');
	}
}

.icon-chat_outline {
	&::before {
		@include iconName('icon-chat_outline');
	}
}

.icon-checkmark {
	&::before {
		@include iconName('icon-checkmark');
	}
}

.icon-checkmark_simple {
	&::before {
		@include iconName('icon-checkmark_simple');
	}
}

.icon-chevron_left {
	&::before {
		@include iconName('icon-chevron_left');
	}
}

.icon-chevron_right {
	&::before {
		@include iconName('icon-chevron_right');
	}
}

.icon-classroom {
	&::before {
		@include iconName('icon-classroom');
	}
}

.icon-close {
	&::before {
		@include iconName('icon-close');
	}
}

.icon-close_alt {
	&::before {
		@include iconName('icon-close_alt');
	}
}

.icon-close_circle {
	&::before {
		@include iconName('icon-close_circle');
	}
}

.icon-cloudy {
	&::before {
		@include iconName('icon-cloudy');
	}
}

.icon-cloudy_fog {
	&::before {
		@include iconName('icon-cloudy_fog');
	}
}

.icon-cloudy_sunny_breaks {
	&::before {
		@include iconName('icon-cloudy_sunny_breaks');
	}
}

.icon-collapse {
	&::before {
		@include iconName('icon-collapse');
	}
}

.icon-condo {
	&::before {
		@include iconName('icon-condo');
	}
}

.icon-contact_email {
	&::before {
		@include iconName('icon-contact_email');
	}
}

.icon-contact_phone {
	&::before {
		@include iconName('icon-contact_phone');
	}
}

.icon-dairy_free {
	&::before {
		@include iconName('icon-dairy_free');
	}
}

.icon-decor {
	&::before {
		@include iconName('icon-decor');
	}
}

.icon-desktop {
	&::before {
		@include iconName('icon-desktop');
	}
}

.icon-detached_house {
	&::before {
		@include iconName('icon-detached_house');
	}
}

.icon-dining {
	&::before {
		@include iconName('icon-dining');
	}
}

.icon-douban {
	&::before {
		@include iconName('icon-douban');
	}
}

.icon-drizzle {
	&::before {
		@include iconName('icon-drizzle');
	}
}

.icon-egg_free {
	&::before {
		@include iconName('icon-egg_free');
	}
}

.icon-email {
	&::before {
		@include iconName('icon-email');
	}
}

.icon-exclusive_amenities {
	&::before {
		@include iconName('icon-exclusive_amenities');
	}
}

.icon-exclusive_offer {
	&::before {
		@include iconName('icon-exclusive_offer');
	}
}

.icon-express_pressing {
	&::before {
		@include iconName('icon-express_pressing');
	}
}

.icon-eye_closed {
	&::before {
		@include iconName('icon-eye_closed');
	}
}

.icon-eye_open {
	&::before {
		@include iconName('icon-eye_open');
	}
}

.icon-eye_outline {
	&::before {
		@include iconName('icon-eye_outline');
	}
}

.icon-facebook {
	&::before {
		@include iconName('icon-facebook');
	}
}

.icon-facebook_tag {
	&::before {
		@include iconName('icon-facebook_tag');
	}
}

.icon-fitness_facilities {
	&::before {
		@include iconName('icon-fitness_facilities');
	}
}

.icon-floorplan {
	&::before {
		@include iconName('icon-floorplan');
	}
}

.icon-fog-patches {
	&::before {
		@include iconName('icon-fog-patches');
	}
}

.icon-fog {
	&::before {
		@include iconName('icon-fog');
	}
}

.icon-foursquare {
	&::before {
		@include iconName('icon-foursquare');
	}
}

.icon-freezing_drizzle {
	&::before {
		@include iconName('icon-freezing_drizzle');
	}
}

.icon-freezing_fog {
	&::before {
		@include iconName('icon-freezing_fog');
	}
}

.icon-freezing_rain {
	&::before {
		@include iconName('icon-freezing_rain');
	}
}

.icon-gallery {
	&::before {
		@include iconName('icon-gallery');
	}
}

.icon-gallery_external {
	&::before {
		@include iconName('icon-gallery_external');
	}
}

.icon-gluten_free {
	&::before {
		@include iconName('icon-gluten_free');
	}
}

.icon-googleplus {
	&::before {
		@include iconName('icon-googleplus');
	}
}

.icon-hamburger {
	&::before {
		@include iconName('icon-hamburger');
	}
}

.icon-hamburguer_small {
	&::before {
		@include iconName('icon-hamburguer_small');
	}
}

.icon-haze_variable_clouds {
	&::before {
		@include iconName('icon-haze_variable_clouds');
	}
}

.icon-heart {
	&::before {
		@include iconName('icon-heart');
	}
}

.icon-hollow_square {
	&::before {
		@include iconName('icon-hollow_square');
	}
}

.icon-hotel {
	&::before {
		@include iconName('icon-hotel');
	}
}

.icon-ice_pellets {
	&::before {
		@include iconName('icon-ice_pellets');
	}
}

.icon-information {
	&::before {
		@include iconName('icon-information');
	}
}

.icon-instagram {
	&::before {
		@include iconName('icon-instagram');
	}
}

.icon-isolated_showers {
	&::before {
		@include iconName('icon-isolated_showers');
	}
}

.icon-item_add {
	&::before {
		@include iconName('icon-item_add');
	}
}

.icon-item_remove {
	&::before {
		@include iconName('icon-item_remove');
	}
}

.icon-itinerary {
	&::before {
		@include iconName('icon-itinerary');
	}
}

.icon-kids {
	&::before {
		@include iconName('icon-kids');
	}
}

.icon-kitchen {
	&::before {
		@include iconName('icon-kitchen');
	}
}

.icon-laundry {
	&::before {
		@include iconName('icon-laundry');
	}
}

.icon-less_sign {
	&::before {
		@include iconName('icon-less_sign');
	}
}

.icon-light_snow {
	&::before {
		@include iconName('icon-light_snow');
	}
}

.icon-location_pin {
	&::before {
		@include iconName('icon-location_pin');
	}
}

.icon-location_pin_brand {
	&::before {
		@include iconName('icon-location_pin_brand');
	}
}

.icon-location_pin_small {
	&::before {
		@include iconName('icon-location_pin_small');
	}
}

.icon-mail {
	&::before {
		@include iconName('icon-mail');
	}
}

.icon-mainly_clear {
	&::before {
		@include iconName('icon-mainly_clear');
	}
}

.icon-mobile {
	&::before {
		@include iconName('icon-mobile');
	}
}

.icon-night_clear_cloudy {
	&::before {
		@include iconName('icon-night_clear_cloudy');
	}
}

.icon-night_cloudy {
	&::before {
		@include iconName('icon-night_cloudy');
	}
}

.icon-night_cloudy_clear {
	&::before {
		@include iconName('icon-night_cloudy_clear');
	}
}

.icon-night_isolated_showers {
	&::before {
		@include iconName('icon-night_isolated_showers');
	}
}

.icon-night_scattered_flurries {
	&::before {
		@include iconName('icon-night_scattered_flurries');
	}
}

.icon-night_scattered_showers {
	&::before {
		@include iconName('icon-night_scattered_showers');
	}
}

.icon-night_snow_rain {
	&::before {
		@include iconName('icon-night_snow_rain');
	}
}

.icon-night_thunderstorm_chance {
	&::before {
		@include iconName('icon-night_thunderstorm_chance');
	}
}

.icon-notes {
	&::before {
		@include iconName('icon-notes');
	}
}

.icon-nut_free {
	&::before {
		@include iconName('icon-nut_free');
	}
}

.icon-occupancy {
	&::before {
		@include iconName('icon-occupancy');
	}
}

.icon-offers {
	&::before {
		@include iconName('icon-offers');
	}
}

.icon-outbound {
	&::before {
		@include iconName('icon-outbound');
	}
}

.icon-pagination_line {
	&::before {
		@include iconName('icon-pagination_line');
	}
}

.icon-partly_cloudy {
	&::before {
		@include iconName('icon-partly_cloudy');
	}
}

.icon-pause_button_large {
	&::before {
		@include iconName('icon-pause_button_large');
	}
}

.icon-pause_button_small {
	&::before {
		@include iconName('icon-pause_button_small');
	}
}

.icon-pinterest {
	&::before {
		@include iconName('icon-pinterest');
	}
}

.icon-play_button_large {
	&::before {
		@include iconName('icon-play_button_large');
	}
}

.icon-play_button_small {
	&::before {
		@include iconName('icon-play_button_small');
	}
}

.icon-plus_sign {
	&::before {
		@include iconName('icon-plus_sign');
	}
}

.icon-pool {
	&::before {
		@include iconName('icon-pool');
	}
}

.icon-pool_alt {
	&::before {
		@include iconName('icon-pool_alt');
	}
}

.icon-qq {
	&::before {
		@include iconName('icon-qq');
	}
}

.icon-quotation_marks_l {
	&::before {
		@include iconName('icon-quotation_marks_l');
	}
}

.icon-quotation_marks_outline {
	&::before {
		@include iconName('icon-quotation_marks_outline');
	}
}

.icon-quotation_marks_s {
	&::before {
		@include iconName('icon-quotation_marks_s');
	}
}

.icon-rain {
	&::before {
		@include iconName('icon-rain');
	}
}

.icon-reception {
	&::before {
		@include iconName('icon-reception');
	}
}

.icon-refreshments {
	&::before {
		@include iconName('icon-refreshments');
	}
}

.icon-room_cleaning {
	&::before {
		@include iconName('icon-room_cleaning');
	}
}

.icon-scattered_flurries {
	&::before {
		@include iconName('icon-scattered_flurries');
	}
}

.icon-scattered_showers {
	&::before {
		@include iconName('icon-scattered_showers');
	}
}

.icon-search {
	&::before {
		@include iconName('icon-search');
	}
}

.icon-shoe {
	&::before {
		@include iconName('icon-shoe');
	}
}

.icon-shopping_cart {
	&::before {
		@include iconName('icon-shopping_cart');
	}
}

.icon-showers {
	&::before {
		@include iconName('icon-showers');
	}
}

.icon-snow {
	&::before {
		@include iconName('icon-snow');
	}
}

.icon-snow_rain {
	&::before {
		@include iconName('icon-snow_rain');
	}
}

.icon-snow_rain_showers {
	&::before {
		@include iconName('icon-snow_rain_showers');
	}
}

.icon-snow_squalls {
	&::before {
		@include iconName('icon-snow_squalls');
	}
}

.icon-spa {
	&::before {
		@include iconName('icon-spa');
	}
}

.icon-sunny {
	&::before {
		@include iconName('icon-sunny');
	}
}

.icon-sunny_cloudy {
	&::before {
		@include iconName('icon-sunny_cloudy');
	}
}

.icon-support_staff {
	&::before {
		@include iconName('icon-support_staff');
	}
}

.icon-tablet {
	&::before {
		@include iconName('icon-tablet');
	}
}

.icon-theatre {
	&::before {
		@include iconName('icon-theatre');
	}
}

.icon-thunderstorm {
	&::before {
		@include iconName('icon-thunderstorm');
	}
}

.icon-thunderstorm_chance {
	&::before {
		@include iconName('icon-thunderstorm_chance');
	}
}

.icon-tours {
	&::before {
		@include iconName('icon-tours');
	}
}

.icon-townhouse {
	&::before {
		@include iconName('icon-townhouse');
	}
}

.icon-transportation {
	&::before {
		@include iconName('icon-transportation');
	}
}

.icon-tray_removal {
	&::before {
		@include iconName('icon-tray_removal');
	}
}

.icon-tree {
	&::before {
		@include iconName('icon-tree');
	}
}

.icon-tree_large {
	&::before {
		@include iconName('icon-tree_large');
	}
}

.icon-tripadvisor {
	&::before {
		@include iconName('icon-tripadvisor');
	}
}

.icon-turn_down_service {
	&::before {
		@include iconName('icon-turn_down_service');
	}
}

.icon-twitter {
	&::before {
		@include iconName('icon-twitter');
	}
}

.icon-u_shape {
	&::before {
		@include iconName('icon-u_shape');
	}
}

.icon-valet_parking {
	&::before {
		@include iconName('icon-valet_parking');
	}
}

.icon-variable_clouds {
	&::before {
		@include iconName('icon-variable_clouds');
	}
}

.icon-vegan {
	&::before {
		@include iconName('icon-vegan');
	}
}

.icon-vegetarian {
	&::before {
		@include iconName('icon-vegetarian');
	}
}

.icon-views {
	&::before {
		@include iconName('icon-views');
	}
}

.icon-washer {
	&::before {
		@include iconName('icon-washer');
	}
}

.icon-wechat {
	&::before {
		@include iconName('icon-wechat');
	}
}

.icon-weibo {
	&::before {
		@include iconName('icon-weibo');
	}
}

.icon-wet_snow {
	&::before {
		@include iconName('icon-wet_snow');
	}
}

.icon-wine {
	&::before {
		@include iconName('icon-wine');
	}
}

.icon-youku {
	&::before {
		@include iconName('icon-youku');
	}
}

.icon-youtube {
	&::before {
		@include iconName('icon-youtube');
	}
}

.icon-africa-middle-east {
	&::before {
		@include iconName('icon-africa-middle-east');
	}
}

.icon-asia-pacific {
	&::before {
		@include iconName('icon-asia-pacific');
	}
}

.icon-beach {
	&::before {
		@include iconName('icon-beach');
	}
}

.icon-central-south-america {
	&::before {
		@include iconName('icon-central-south-america');
	}
}

.icon-europe {
	&::before {
		@include iconName('icon-europe');
	}
}

.icon-exclusives-buyouts {
	&::before {
		@include iconName('icon-exclusives-buyouts');
	}
}

.icon-golf {
	&::before {
		@include iconName('icon-golf');
	}
}

.icon-north-america {
	&::before {
		@include iconName('icon-north-america');
	}
}

.icon-outdoor-function-space {
	&::before {
		@include iconName('icon-outdoor-function-space');
	}
}

.icon-resort {
	&::before {
		@include iconName('icon-resort');
	}
}

.icon-resort-urban {
	&::before {
		@include iconName('icon-resort-urban');
	}
}

.icon-urban {
	&::before {
		@include iconName('icon-urban');
	}
}

.icon-call_alt_inverted {
	&::before {
		@include iconName('icon-call_alt_inverted');
	}
}

.icon-airline_check_in {
	&::before {
		@include iconName('icon-airline_check_in');
	}
}

.icon-flight_arrival {
	&::before {
		@include iconName('icon-flight_arrival');
	}
}

.icon-itinerary_default {
	&::before {
		@include iconName('icon-itinerary_default');
	}
}

.icon-mail_default_circle {
	&::before {
		@include iconName('icon-mail_default_circle');
	}
}

.icon-ramdan_menu {
	&::before {
		@include iconName('icon-ramdan_menu');
	}
}

.icon-shuttle_bus_schedule {
	&::before {
		@include iconName('icon-shuttle_bus_schedule');
	}
}

.icon-terms_and_conditions {
	&::before {
		@include iconName('icon-terms_and_conditions');
	}
}

.icon-book {
	&::before {
		@include iconName('icon-book');
	}
}

.icon-flight_departure {
	&::before {
		@include iconName('icon-flight_departure');
	}
}

.icon-itinerary_new {
	&::before {
		@include iconName('icon-itinerary_new');
	}
}

.icon-mobile_key {
	&::before {
		@include iconName('icon-mobile_key');
	}
}

.icon-residence {
	&::before {
		@include iconName('icon-residence');
	}
}

.icon-standard_wifi {
	&::before {
		@include iconName('icon-standard_wifi');
	}
}

.icon-time {
	&::before {
		@include iconName('icon-time');
	}
}

.icon-chat_default_circle {
	&::before {
		@include iconName('icon-chat_default_circle');
	}
}

.icon-flight_info {
	&::before {
		@include iconName('icon-flight_info');
	}
}

.icon-multilingual_concierge {
	&::before {
		@include iconName('icon-multilingual_concierge');
	}
}

.icon-resort_activities {
	&::before {
		@include iconName('icon-resort_activities');
	}
}

.icon-takeaway {
	&::before {
		@include iconName('icon-takeaway');
	}
}

.icon-tv_channel_listings {
	&::before {
		@include iconName('icon-tv_channel_listings');
	}
}

.icon-festive_menu {
	&::before {
		@include iconName('icon-festive_menu');
	}
}

.icon-in-room_dining {
	&::before {
		@include iconName('icon-in-room_dining');
	}
}

.icon-luxury_bath_products {
	&::before {
		@include iconName('icon-luxury_bath_products');
	}
}

.icon-pressreader {
	&::before {
		@include iconName('icon-pressreader');
	}
}

.icon-safety_and_security {
	&::before {
		@include iconName('icon-safety_and_security');
	}
}

.icon-telephone_directory {
	&::before {
		@include iconName('icon-telephone_directory');
	}
}

.icon-weddings {
	&::before {
		@include iconName('icon-weddings');
	}
}

.icon-commercial_flight {
	&::before {
		@include iconName('icon-commercial_flight');
	}
}

.icon-private_flight {
	&::before {
		@include iconName('icon-private_flight');
	}
}

.icon-smoke_free {
	&::before {
		@include iconName('icon-smoke_free');
	}
}

.icon-photo_and_video {
	&::before {
		@include iconName('icon-photo_and_video');
	}
}

.icon-more {
	&::before {
		@include iconName('icon-more');
	}
}

.icon-credit_card {
	&::before {
		@include iconName('icon-credit_card');
	}
}

.icon-bellcart_luggage {
	&::before {
		@include iconName('icon-bellcart_luggage');
	}
}

.icon-shopping {
	&::before {
		@include iconName('icon-shopping');
	}
}

.icon-pet {
	&::before {
		@include iconName('icon-pet');
	}
}

.icon-covid_testing {
	&::before {
		@include iconName('icon-covid_testing');
	}
}

.icon-diving_snorkeling {
	&::before {
		@include iconName('icon-diving_snorkeling');
	}
}

.icon-enhanced_cleaning {
	&::before {
		@include iconName('icon-enhanced_cleaning');
	}
}

.icon-faqs {
	&::before {
		@include iconName('icon-faqs');
	}
}

.icon-find_hotel_status {
	&::before {
		@include iconName('icon-find_hotel_status');
	}
}

.icon-health_and_safety {
	&::before {
		@include iconName('icon-health_and_safety');
	}
}

.icon-mask {
	&::before {
		@include iconName('icon-mask');
	}
}

.icon-surfing {
	&::before {
		@include iconName('icon-surfing');
	}
}

.icon-temperature_check {
	&::before {
		@include iconName('icon-temperature_check');
	}
}

.icon-unique_stays {
	&::before {
		@include iconName('icon-unique_stays');
	}
}

.icon-wellness {
	&::before {
		@include iconName('icon-wellness');
	}
}

.icon-adventure {
	&::before {
		@include iconName('icon-adventure');
	}
}

.icon-arts_culture {
	&::before {
		@include iconName('icon-arts_culture');
	}
}

.icon-award_winning_spa {
	&::before {
		@include iconName('icon-award_winning_spa');
	}
}

.icon-cocktail {
	&::before {
		@include iconName('icon-cocktail');
	}
}

.icon-cold {
	&::before {
		@include iconName('icon-cold');
	}
}

.icon-comfort {
	&::before {
		@include iconName('icon-comfort');
	}
}

.icon-culinary_experience {
	&::before {
		@include iconName('icon-culinary_experience');
	}
}

.icon-dress_code {
	&::before {
		@include iconName('icon-dress_code');
	}
}

.icon-experience {
	&::before {
		@include iconName('icon-experience');
	}
}

.icon-extension {
	&::before {
		@include iconName('icon-extension');
	}
}

.icon-family {
	&::before {
		@include iconName('icon-family');
	}
}

.icon-hot {
	&::before {
		@include iconName('icon-hot');
	}
}

.icon-late_checkout {
	&::before {
		@include iconName('icon-late_checkout');
	}
}

.icon-mountain {
	&::before {
		@include iconName('icon-mountain');
	}
}

.icon-my_neighborhood {
	&::before {
		@include iconName('icon-my_neighborhood');
	}
}

.icon-order_grocery {
	&::before {
		@include iconName('icon-order_grocery');
	}
}

.icon-outdoor_experience {
	&::before {
		@include iconName('icon-outdoor_experience');
	}
}

.icon-premium_wifi {
	&::before {
		@include iconName('icon-premium_wifi');
	}
}

.icon-private_jet {
	&::before {
		@include iconName('icon-private_jet');
	}
}

.icon-private_retreats {
	&::before {
		@include iconName('icon-private_retreats');
	}
}

.icon-property_info {
	&::before {
		@include iconName('icon-property_info');
	}
}

.icon-residence_a_la_carte {
	&::before {
		@include iconName('icon-residence_a_la_carte');
	}
}

.icon-residence_dining {
	&::before {
		@include iconName('icon-residence_dining');
	}
}

.icon-residence_my_building {
	&::before {
		@include iconName('icon-residence_my_building');
	}
}

.icon-residence_services {
	&::before {
		@include iconName('icon-residence_services');
	}
}

.icon-room_upgrade {
	&::before {
		@include iconName('icon-room_upgrade');
	}
}

.icon-services_and_amenities {
	&::before {
		@include iconName('icon-services_and_amenities');
	}
}

.icon-ski {
	&::before {
		@include iconName('icon-ski');
	}
}

.icon-sporting_events {
	&::before {
		@include iconName('icon-sporting_events');
	}
}

.icon-sustainable_travel {
	&::before {
		@include iconName('icon-sustainable_travel');
	}
}

.icon-tennis {
	&::before {
		@include iconName('icon-tennis');
	}
}

.icon-windy {
	&::before {
		@include iconName('icon-windy');
	}
}

.icon-parking {
	&::before {
		@include iconName('icon-parking');
	}
}

.icon-meetings_and_events {
	&::before {
		@include iconName('icon-meetings_and_events');
	}
}

.icon-check_in {
	&::before {
		@include iconName('icon-check_in');
	}
}

.icon-check_out {
	&::before {
		@include iconName('icon-check_out');
	}
}

.icon-itinerary_notification {
	&::before {
		@include iconName('icon-itinerary_notification');
	}
}

.icon-settings {
	&::before {
		@include iconName('icon-settings');
	}
}

.icon-call_circle_outline {
	&::before {
		@include iconName('icon-call_circle_outline');
	}
}

.icon-chat_notification {
	&::before {
		@include iconName('icon-chat_notification');
	}
}

.icon-chat_notification_large {
	&::before {
		@include iconName('icon-chat_notification_large');
	}
}

.icon-details {
	&::before {
		@include iconName('icon-details');
	}
}

.icon-dining_reservation {
	&::before {
		@include iconName('icon-dining_reservation');
	}
}

.icon-email_fill {
	&::before {
		@include iconName('icon-email_fill');
	}
}

.icon-exclusive_amenities_filled {
	&::before {
		@include iconName('icon-exclusive_amenities_filled');
	}
}

.icon-location_pin_large_fill {
	&::before {
		@include iconName('icon-location_pin_large_fill');
	}
}

.icon-location_pin_small_fill {
	&::before {
		@include iconName('icon-location_pin_small_fill');
	}
}

.icon-no_available_date {
	&::before {
		@include iconName('icon-no_available_date');
	}
}

.icon-alert {
	&::before {
		@include iconName('icon-alert');
	}
}

.icon-edit {
	&::before {
		@include iconName('icon-edit');
	}
}

.icon-environmental_programs {
	&::before {
		@include iconName('icon-environmental_programs');
	}
}

.icon-environment {
	&::before {
		@include iconName('icon-environment');
	}
}

.icon-energy_reduction {
	&::before {
		@include iconName('icon-energy_reduction');
	}
}

.icon-energy_carbon_and_water_reduction {
	&::before {
		@include iconName('icon-energy_carbon_and_water_reduction');
	}
}

.icon-documents_and_downloads {
	&::before {
		@include iconName('icon-documents_and_downloads');
	}
}

.icon-diversity_inclusion_and_belonging {
	&::before {
		@include iconName('icon-diversity_inclusion_and_belonging');
	}
}

.icon-biodiversity_and_conservation {
	&::before {
		@include iconName('icon-biodiversity_and_conservation');
	}
}

.icon-governance {
	&::before {
		@include iconName('icon-governance');
	}
}

.icon-handshake {
	&::before {
		@include iconName('icon-handshake');
	}
}

.icon-purchasing_practices {
	&::before {
		@include iconName('icon-purchasing_practices');
	}
}

.icon-social {
	&::before {
		@include iconName('icon-social');
	}
}

.icon-sustainable_food_and_beverage {
	&::before {
		@include iconName('icon-sustainable_food_and_beverage');
	}
}

.icon-waste_reduction {
	&::before {
		@include iconName('icon-waste_reduction');
	}
}

.icon-water_reduction {
	&::before {
		@include iconName('icon-water_reduction');
	}
}

.icon-whats_included {
	&::before {
		@include iconName('icon-whats_included');
	}
}

.icon-caret_up {
	&::before {
		@include iconName('icon-caret_up');
	}
}

.icon-lock {
	&::before {
		@include iconName('icon-lock');
	}
}

.icon-safety_and_security_inverted {
	&::before {
		@include iconName('icon-safety_and_security_inverted');
	}
}

.icon-pr_and_hotel {
	&::before {
		@include iconName('icon-pr_and_hotel');
	}
}

.icon-shopping_cart_outlined {
	&::before {
		@include iconName('icon-shopping_cart_outlined');
	}
}

.icon-maintenance {
	&::before {
		@include iconName('icon-maintenance');
	}
}

.icon-storage {
	&::before {
		@include iconName('icon-storage');
	}
}

.icon-yacht {
	&::before {
		@include iconName('icon-yacht');
	}
}

.icon-horseback_riding {
	&::before {
		@include iconName('icon-horseback_riding');
	}
}

.icon-yoga {
	&::before {
		@include iconName('icon-yoga');
	}
}

.icon-download {
	&::before {
		@include iconName('icon-download');
	}
}

.icon-person {
	&::before {
		@include iconName('icon-person');
	}
}

.icon-filter {
	&::before {
		@include iconName('icon-filter');
	}
}

.icon-switch_view {
	&::before {
		@include iconName('icon-switch_view');
	}
}

.icon-list {
	&::before {
		@include iconName('icon-list');
	}
}

.icon-castle {
	&::before {
		@include iconName('icon-castle');
	}
}

.icon-pillow {
	&::before {
		@include iconName('icon-pillow');
	}
}

.icon-checkmark_fill {
	&::before {
		@include iconName('icon-checkmark_fill');
	}
}

.icon-checkmark_outline {
	&::before {
		@include iconName('icon-checkmark_outline');
	}
}

