.AnchorLinks {
	text-align: center;

	&-link {
		display: inline-block;
		margin-bottom: 10px;
		text-decoration: none;
		border: 0;
		white-space: nowrap;
		padding-left: 25px;
		padding-right: 25px;
		text-underline-position: under;
		text-underline-offset: 3px;
		&:hover {
			text-decoration: underline;
		}

		@include viewport(large) {
			padding-right: 30px;
			padding-left: 30px;
			margin-bottom: 20px;
		}
	}

	&-hidden {
		display: none;
	}

	&-container {
		.CTA--underlined--dark .CTA--underlined-light {
			margin-bottom: -15px;
		}
		:not(:first-child) {
			border-left: 1px solid;
			@include rtl {
				:not(:first-child) {
					border-right: 1px solid;
				}
				border-left: 0;
			}
		}
		@include rtl {
			:not(:first-child) {
				border-right: 1px solid;
			}
			border-left: 0;
		}
		display: flex;
		flex-direction: row;
		max-width: 100%;
		overflow: hidden;
		overflow-x: auto;
		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
		scrollbar-width: none;

		@include viewport(large) {
			max-width: 100%;
			margin-bottom: -20px;
			justify-content: center;
			flex-wrap: wrap;
		}
		&-1 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-width: 100%;

			@include viewport(large) {
				max-width: 100%;
				justify-content: center;
				flex-wrap: wrap;
			}
		}
		&-2 {
			display: flex;
			flex-direction: column;
			justify-content: center;
			max-width: 100%;

			.AnchorLinks-link {
				border: none;
				@include rtl {
					border: none;
				}
			}
			@include viewport(large) {
				display: flex;
				flex-direction: row;
				max-width: 100%;
				justify-content: center;
				:not(:first-child) {
					border-left: 1px solid;
					@include rtl {
						:not(:first-child) {
							border-right: 1px solid;
						}
						border-left: 0;
					}
				}
				@include rtl {
					:not(:first-child) {
						border-right: 1px solid;
					}
					border-left: 0;
				}
			}
		}
	}
	&--GrayBox {
		background: var(--color-surface-raised-subtle, #f5f5f5);
		padding: 20px 0 10px;
		min-width: 100vw;
	}

	&-anchors {
		background: var(--color-surface-default, #fff);
		display: flex;
		align-items: flex-start;
		padding: 20px 0 10px;
		width: calc(100vw - 1rem);

		.AnchorLinks-container {
			margin: 0;

			.AnchorLinks-link {
				margin: 0 0 10px;
			}
		}
	}
}
.AnchorLinks-added-link {
	display: none !important;
}
